<template>
   <v-card>
      <v-card-title class="py-1 px-1">
         <v-btn icon @click="$emit('cancel')" class="mr-2"><v-icon>mdi-close</v-icon></v-btn>
         {{isCreate?'Thêm văn bản mới':'Chỉnh sửa văn bản'}}
         <v-spacer></v-spacer>
         <v-btn @click="isCreate?$emit('addNew', vanban):$emit('update', vanban)" color='primary'>
            Lưu
         </v-btn>
      </v-card-title>
      <v-card-text v-if="vanban" style='font-size:13px;'>
         <div class="d-flex align-center py-1">
            <div style="width:100px;" class="d-flex-inline">
               Công ty:
            </div>
            <div style="width:280px;" class="d-flex-inline">
            <v-text-field v-model="vanban.congty"
               flat dense outlined
               style='font-size: 15px;'
               single-line hide-details
            ></v-text-field>
            </div>
         </div>

         <div>
            <div class="d-inline-block pr-3" style="vertical-align:top;">
               <div class="d-flex align-center py-1">
                  <div style="width:100px;" class="d-flex-inline">
                     Khối:
                  </div>
                  <div style="width:280px;" class="d-flex-inline">
                     <v-text-field v-model="vanban.khoi"
                     flat dense outlined
                     style='font-size: 14px;'
                     single-line hide-details
                     ></v-text-field>
                  </div>
               </div>
            </div>

            <div class="d-inline-block pr-3" style="vertical-align:top;">
               <div class="d-flex align-center py-1">
                  <div style="width:100px;" class="d-flex-inline">
                     Phòng ban:
                  </div>
                  <div style="width:280px;" class="d-flex-inline">
                     <v-select
                        v-model="vanban.department"
                        :items="departments"
                        item-value="_id"
                        item-text="name"
                        color="#DD0011"
                        outlined dense style="font-size:14px;"
                        single-line hide-details
                      ></v-select>
                  </div>
               </div>
            </div>
         </div>

         <div>
            <div class="d-inline-block pr-3" style="vertical-align:top;">
               <div class="d-flex align-center py-1">
                  <div style="width:100px;" class="d-flex-inline">
                     Chức vụ:
                  </div>
                  <div style="width:280px;" class="d-flex-inline">
                     <v-text-field v-model="vanban.chucvu"
                     flat dense outlined
                     style='font-size: 14px;'
                     single-line hide-details
                     ></v-text-field>
                  </div>
               </div>
            </div>

            <div class="d-inline-block pr-3" style="vertical-align:top;">
               <div class="d-flex align-center py-1">
                  <div style="width:100px;" class="d-flex-inline">
                     Vị trí:
                  </div>
                  <div style="width:280px;" class="d-flex-inline">
                     <v-text-field v-model="vanban.vitri"
                     flat dense outlined
                     style='font-size: 14px;'
                     single-line hide-details
                     ></v-text-field>
                  </div>
               </div>
            </div>
         </div>

         <div class="d-flex align-center py-1">
            <div style="width:100px;" class="d-flex-inline">
               Tên văn bản:
            </div>
            <div style="width:280px;" class="d-flex-inline">
            <v-text-field v-model="vanban.ten"
               flat dense outlined
               style='font-size: 15px;'
               single-line hide-details
            ></v-text-field>
            </div>
         </div>

         <div class="d-flex align-center py-1">
            <div style="width:100px;" class="d-flex-inline">
               Loại văn bản:
            </div>
            <div style="width:280px;" class="d-flex-inline">
               <v-text-field v-model="vanban.loai"
                  flat dense outlined
                  style='font-size: 15px;'
                  single-line hide-details
               ></v-text-field>
            </div>
         </div>

         <div class="d-flex align-center py-1">
            <div style="width:100px;" class="d-flex-inline">
               Thời hạn:
            </div>
            <div style="width:280px;" class="d-flex-inline">
               <v-text-field v-model="vanban.thoihan"
                  flat dense outlined
                  style='font-size: 15px;'
                  single-line hide-details
               ></v-text-field>
            </div>
         </div>

         <div class="d-flex align-center py-1">
            <div style="width:100px;" class="d-flex-inline">
               Ngày ký:
            </div>
            <div style="width:280px;" class="d-flex-inline">
               <date-input v-model='vanban.ngayky'/>
            </div>
         </div>

         <div>
            <div class="d-inline-block pr-3" style="vertical-align:top;">
               <div class="d-flex align-center py-1">
                  <div style="width:100px;" class="d-flex-inline">
                     Ngày hiệu lực:
                  </div>
                  <div style="width:280px;" class="d-flex-inline">
                     <date-input v-model='vanban.ngayhieuluc'/>
                  </div>
               </div>
            </div>

            <div class="d-inline-block pr-3" style="vertical-align:top;">
               <div class="d-flex align-center py-1">
                  <div style="width:100px;" class="d-flex-inline">
                     Ngày hết hạn:
                  </div>
                  <div style="width:280px;" class="d-flex-inline">
                     <date-input v-model='vanban.ngayhethan'/>
                  </div>
               </div>
            </div>
         </div>

         <div>
            <div class="d-inline-block pr-3" style="vertical-align:top;">
               <div class="d-flex align-center py-1">
                  <div style="width:100px;" class="d-flex-inline">
                     Ngày đề xuất:
                  </div>
                  <div style="width:280px;" class="d-flex-inline">
                     <date-input v-model='vanban.ngaydexuat'/>
                  </div>
               </div>
            </div>
         </div>

         <div>
            <div class="d-inline-block pr-3" style="vertical-align:top;">
               <div class="d-flex align-center py-1">
                  <div style="width:100px;" class="d-flex-inline">
                     Trạng thái:
                  </div>
                  <div style="width:280px;" class="d-flex-inline">
                        <v-text-field v-model="vanban.trangthai"
                           flat dense outlined
                           style='font-size: 15px;'
                           single-line hide-details
                        ></v-text-field>
                  </div>
               </div>
            </div>
            <div class="d-inline-block pr-3" style="vertical-align:top;">
               <div class="d-flex align-center py-1">
                  <div style="width:100px;" class="d-flex-inline">
                     Lý do:
                  </div>
                  <div style="width:280px;" class="d-flex-inline">
                        <v-text-field v-model="vanban.lydo"
                           flat dense outlined
                           style='font-size: 15px;'
                           single-line hide-details
                        ></v-text-field>
                  </div>
               </div>
            </div>
         </div>


      </v-card-text>
   </v-card>
</template>

<script>
export default {
   props: ['isCreate', 'vanban', 'departments']
}
</script>