<template>
  <v-card class="pa-0">
    <v-card-title v-if="multiple"
      class="py-1" style="border-bottom: 1px solid #CCCCCC;">
      <v-btn text color="green" class="text-none" @click="closeMenu">
        Xong
      </v-btn>
      <v-spacer />

      <v-btn
        text
        color="#333333"
        class="text-none"
        @click="setAllItemSelected"
      >
        Select All
      </v-btn>
      <v-btn
        text
        color="#333333"
        class="text-none"
        @click="selected_items = []"
      >
        Select None
      </v-btn>
    </v-card-title>
    <v-card-text class="pa-0">
      <div class="py-1 pt-2 px-2">
        <v-text-field
          class="pr-0"
          v-model="searchString"
          append-icon="mdi-magnify"
          label="Search"
          outlined
          single-line
          hide-details
          clearable
          dense
          style="width:100%;max-width:100%;"
        ></v-text-field>
      </div>
      <div style="overflow-y: scroll;width:100%;">
        <v-data-table
          v-model="selected_items"
          :headers="headers"
          :items="items"
          :items-per-page="15"
          :search="searchString"
          :loading="loading"
          loading-text="Loading... Please wait"
          dense
          style="padding:6px;"
          :show-select="multiple"
          hide-default-footer
          :page.sync="depsPage"
          @page-count="pageCount = $event"
          :mobile-breakpoint="0"
          :single-select="!multiple"
          @click:row="onRowClick"
        >
        </v-data-table>
      </div>
      <div v-if="pageCount > 1" class="text-center pt-2">
        <v-pagination v-model="depsPage" :length="pageCount"></v-pagination>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    text: { default: "", type: String },
    multiple: { default: false, type: Boolean },
    disabled: { default: false, type: Boolean },
    large: { default: false, type: Boolean },
    placeholder: { default: "Chọn thiết bị", type: String },
    mandatory: { default: false, type: Boolean },
    fullWidth: { default: false, type: Boolean },
    type: { default: 'thiet-bi', type: String },
  },
  computed: {
    display_text: function() {
      if (this.selected_items.length > 0) {
        if (this.selected_items.length == 1) {
          return this.selected_items[0].display_name;
        }

        if (this.selected_items.length == this.items.length) {
          return "Tất cả thiết bị";
        }

        if (this.selected_items.length >= 1) {
          return " " + this.selected_items.length + " thiết bị";
        }
      }
      return "Chọn thiết bị";
    },
    // displayItems() {
    //   if (this.searchString) {
    //     return this.items.filter((i) =>
    //       i.display_name.includes(this.searchString)
    //     )
    //   } else {
    //     return this.items
    //   }
    // },
  },
  data() {
    return {
      showMenu: false,
      pageCount: 0,
      depsPage: 1,
      lentProperties: [],
      items: [],
      selected_items: [],
      searchString: "",
      loading: false,
      headers: [
        {
          text: "QrCode",
          value: "qr_code",
          align: "start",
          class: "textQRCode",
          sortable: true,
          width: "160px"
        },
        {
          text: "Tên thiết bị",
          value: "name",
          align: "start",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    closeMenu() {
      this.showMenu = false
      this.emitSelectedItems()
    },
    emitSelectedItems() {
      this.$emit('picked', this.selected_items)
      // this.$emit("input", this.selected_items);
    },
    onRowClick (item) {
      if (!this.multiple) {
        this.selected_items = [item]
        this.closeMenu()
      }
    },
    async fetchAllProperties() {
      this.loading = true
      this.items = []
      this.selected_items = []
      let paramsProperties = {
        enable: true,
        type: 'thiet-bi'
      };
      let res = await this.axios.get(
        this.$root.apiAssetMana + "/properties?limit=10000", 
        { params: paramsProperties })
      if (res.data.status == "OK") {
        let properties = res.data.content.items
        this.items = properties.filter(prop => !this.lentProperties.includes(prop._id))
      }
      this.loading = false
    },
    async fetchAllActiveLends () {
      this.loading = true
      let query = "types=LEND-ASSET&&statuses=LEND"
      try {
        let res = await this.axios.get(this.$root.apiAssetMana + `/assetLends?${query}`)
        if (res.data.status == "OK") {
            this.lentProperties = res.data.content.map(lend => lend.property._id)
        } else {
            alert('Lỗi! ' + res.data.message)
        }
      } catch (err) {
        alert('Lỗi! ' + err)
      }
      this.loading = false
    },
    setAllItemSelected() {
      if(this.multiple){
        this.selected_items = this.items
      }
    },
  },
  created () {
    // console.log('On PanelPickEquipment created')
    this.$nextTick(async () => {
      await this.fetchAllActiveLends()
      // console.log(this.lentProperties)
      await this.fetchAllProperties()

    });
  },
};
</script>

<style>
  .textQRCode {
    font-weight: 700;
    color:black;
    font-size: 13px;
  }
</style>
