<template>
   <div>
      <v-data-table
         :headers="accessHeaders"
         :items="accessLogs"
         :page.sync="accessPage"
         :items-per-page="9"
         hide-default-footer dense
         class="elevation-1"
         @page-count="pageCount = $event"
         :search="accessSearchStr"
         :mobile-breakpoint="0"
      >
         <template v-slot:item.qr_code="{ item }">
            <b>{{item.qr_code}}</b> 
            <span class='ml-1'>{{item.devName || ''}}</span>
         </template>
         <template v-slot:item.appear_at="{ item }">
            {{item.appearAtStr}}
         </template>
      </v-data-table>
      <div v-if="pageCount>1"
         class="text-center pt-2">
         <v-pagination
            v-model="accessPage"
            :length="pageCount"
            ></v-pagination>
      </div>
   </div>
</template>

<script>
export default {
   props: ['devIds', 'qrCodes'],
   data () {
      return {
         properties: [],
         accessLogs: [],
         accessHeaders: [  
            {
               text: "Mã QR",
               align: "start",
               sortable: true,
               value: "qr_code",
            },
            {
               text: "Vị trí",
               align: "start",
               sortable: true,
               value: "doorName",
            },
            {
               text: "Ngày giờ",
               align: "start",
               sortable: true,
               value: "appear_at",
            }
         ],
         accessPage: 0,
         pageCount: 0,
         accessSearchStr: '',
      }
   },
   methods: {
      async fetchProperties() {
         this.loading = true;
         this.selected_properties = [];
         let paramsProperties = {
            // created_at_min: moment(self.params.timeRange.start).toISOString(),
            // created_at_max: moment(self.params.timeRange.end).toISOString(),
            // show_deleted: true
            type: 'thiet-bi'
         };
         try {
            let res = await this.axios
               .get(this.$root.apiAssetMana + "/properties", {
                  headers: { "x-auth": this.token },
                  params: paramsProperties,
               })
            // + 'owners='+self.$root.site_id
            
            if (res.data.status == "OK") {
               this.properties = res.data.content.items;
               this.properties = this.properties.filter(i => i.type == 'thiet-bi')
               // console.log(self.properties)
            } else {
               console.log(res.data.message);
            }
         } catch (err)  { console.log(err.response?err.response.data:err) }
         this.loading = false
      },
      async fetchRecentAccessLog () {
         try {
            let res = await this.axios
               .get(this.$root.apiAssetManaDev + `/appear-dev-logs?limit=200${this.qrCodes?('&qr_codes='+this.qrCodes):''}`, {
               // .get(this.$root.apiAssetManaDev + `/appear-dev-logs?limit=200`, {
                  headers: { "x-auth": this.token },
               })
            // + 'owners='+self.$root.site_id
            
            if (res.data.status == "OK") {
               this.accessLogs = res.data.content.items;
               this.accessLogs.forEach(a => {
                  a.appearAtStr = this.$moment(a.appear_at).format('HH:mm, DD/MM/YY')
                  a.devName = this.getNameByQRCode(a.qr_code)
                  // a.doorName = a.position_door=='OUT-DOOR'?'Cửa kho':'Trong kho'
                  if(a.position_door=='OUT-DOOR') {
                     a.devName += '.'
                  }
                  a.doorName = "Kho"
               })
               // console.log(this.accessLogs)
            } else {
               console.log(res.data);
            }
         } catch (err) { console.log(err.response?err.response.data:err) }
      },
      getNameByQRCode (qrCode) {
         let returnData = ""
         let matchProperty = this.properties.find(p => p.qr_code == qrCode)
         if(matchProperty) returnData = matchProperty.name
         return returnData
      }
   },
   async created () {
      await this.fetchProperties()
      await this.fetchRecentAccessLog()
   }
}
</script>