<template>
   <vc-date-picker v-model="date" locale="vi-VN" :masks="masks" @input="onDateInput">
      <template v-slot="{ inputValue, inputEvents }">
         <input class="px-4"
            style="border:1px solid #999999;border-radius: 4px;height:40px;width:100%;"
            :value="inputValue"
            v-on="inputEvents"
         />
      </template>
   </vc-date-picker>
</template>

<script>
export default {
   props: ['value'],
   watch: {
      value: {
         immediate: true,
         handler(val) {
            if(val){
               let tempDate = new Date(val)
               if (tempDate != this.date) this.date = tempDate
            } else {
               this.date = null
            }
         }
      }
   },
   data() {
      return {
         date: null,
         masks: {
            input: 'DD/MM/YYYY',
         },
      }
   },
   methods: {
      onDateInput: function (date) {
         // console.log(`onDateInput`)
         // console.log(date)
         this.$emit('input', date)
      }
   }
}
</script>