<template>
  <v-container
    fluid
    style="background-color:#f2f2f2 !important;min-height:100%;"
    class="pa-0"
  >
    <v-row class="ma-0 fill-height" style="padding:2px;border: 1.8px solid teal;border-radius: 6px;" >
      <v-col cols="12" class="pa-0">
        <v-card flat>
          <v-card-title
            :class="{
              'px-2': $vuetify.breakpoint.smAndDown,
            }"
          >
            <v-btn
              outlined
              class="mr-4 mb-2"
              color="grey darken-2"
              @click="setToday"
            >
              Today
            </v-btn>
            <div class="d-flex mb-2 mr-2">
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-toolbar-title
                class="d-flex align-center justify-center"
                v-if="$refs.calendar"
              >
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </div>

            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mb-2"
                  outlined
                  color="grey darken-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Ngày</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Tuần</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Tháng</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 Ngày</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col class="pa-0" cols="12">
        <v-sheet height="640">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="displayCalendar"
            :event-color="getEventColor"
            :type="getType"
            event-overlap-mode="stack"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          ></v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import PropertiesDropdown from "@/components/Properties/Rooms/RoomsDropdown.vue";
export default {
  props: ["data", "allowShowEvent", "itemPath"],
  data: () => ({
    focus: '',
    type: "day",
    typeToLabel: {
      month: "Tháng",
      week: "Tuần",
      day: "Ngày",
      "4day": "4 Ngày",
    },
    calendarStartDate: new Date(),
    calendarEndDate: new Date(),
  }),
  components: {
    // PropertiesDropdown,
  },
  computed: {
    displayCalendar() {
      let self = this;
      let result = [];
      if (self.data.length <= 0) return [];
      result = self.data;

      return result;
    },
    getType(){
      return this.type;
    }
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = new Date();
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      if(this.allowShowEvent){
        this.$router.push({ path: `/${this.itemPath || 'rooms-sessions'}/` + event.data._id });
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      this.calendarStartDate = new Date(
        start.year,
        start.month - 1,
        start.day,
        0,
        0,
        0,
        0
      );
      this.calendarEndDate = new Date(
        end.year,
        end.month - 1,
        end.day,
        23,
        59,
        59,
        0
      );
       this.notifyDateChange()
    },
    notifyDateChange(){
      this.$emit('calendar-change-range',{
        range: {
          start: this.calendarStartDate,
          end: this.calendarEndDate
        },
        rangeMode: this.type
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      // this.$refs.calendar.checkChange()
      // this.setToday()
    });
  },
};
</script>
